






import { BLink } from "bootstrap-vue";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({ components: { "b-link": BLink } })
export default class ICMLink extends Vue {
	@Prop() to: string;
	@Prop() href: string;
	@Prop() target: string;

	@Prop({ default: "1rem" }) fontSize: string;

	get styles() {
		return {
			fontSize: this.fontSize,
		};
	}
}

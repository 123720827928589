<template>
	<b-row>
		<b-col class="background p-0 d-none d-lg-block col-lg-6">
			<b-img src="/login-background.jpg" fluid alt="fundo" class="login-background" />
		</b-col>
		<b-col class="d-flex flex-column align-items-center justify-content-center vh-100">
			<div class="wrapper font-medium-1">
				<b-img src="/logo.png" alt="logo" class="d-block mx-auto" style="max-width: 100px" />

				<div class="align-self-start">
					<b-card-title class="mb-50 font-weight-bolder font-medium-3">Faça seu login</b-card-title>
					<b-card-text class="mb-2">Entre com a sua conta e navegue pelo site</b-card-text>
				</div>

				<!-- form -->
				<b-form class="w-100 mt-2" @submit.prevent>
					<!-- email -->
					<b-form-group label-for="email" label="Email" label-class="font-medium-1">
						<b-form-input
							id="email"
							v-model="loginData.email"
							name="login-email"
							placeholder="usuario@empresa.com.br"
							autofocus
						/>
					</b-form-group>

					<!-- password -->
					<b-form-group label-for="password" label="Senha" label-class="font-medium-1">
						<b-input-group class="input-group-merge">
							<b-form-input
								id="password"
								v-model="loginData.password"
								:type="passwordFieldType"
								class="form-control-merge"
								name="login-password"
								placeholder="Senha"
							/>

							<b-input-group-append is-text>
								<feather-icon class="cursor-pointer" :icon="passwordToggleIcon" @click="togglePasswordVisibility" />
							</b-input-group-append>
						</b-input-group>
					</b-form-group>

					<b-form-group>
						<div class="d-flex justify-content-between">
							<b-form-checkbox id="remember-me" v-model="loginData.shouldPersistSession">
								<b-card-text class="font-medium-1">Manter-se conectado</b-card-text>
							</b-form-checkbox>

							<icm-link href="https://suaescolaideal.com.br/forgot" target="_blank">Esqueceu a senha?</icm-link>
						</div>
					</b-form-group>

					<!-- submit button -->
					<b-button variant="primary" type="submit" block :disabled="loading" @click="signIn" pill>
						<b-spinner v-if="loading" small />
						Entrar
					</b-button>

					<div class="mt-1 d-flex justify-content-between align-items-center">
						<b-card-text class="font-medium-1 m-0">Novo na plataforma?</b-card-text>
						<icm-link fontSize="12px" href="https://suaescolaideal.com.br/cadastro/escola" target="_blank">
							Crie uma conta
						</icm-link>
					</div>
				</b-form>
			</div>
			<!-- Login v1 -->
		</b-col>
	</b-row>
</template>

<script>
	import {
		BButton,
		BForm,
		BFormInput,
		BFormGroup,
		BImg,
		BCardTitle,
		BCardText,
		BInputGroup,
		BInputGroupAppend,
		BSpinner,
		BFormCheckbox,
		BRow,
		BCol,
	} from "bootstrap-vue";
	import { togglePasswordVisibility } from "@core/mixins/ui/forms";
	import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
	import ICMLink from "@/layouts/components/base/ICMLink.vue";

	export default {
		components: {
			// BSV
			BButton,
			BForm,
			BFormCheckbox,
			BFormInput,
			BFormGroup,
			BCardTitle,
			BImg,
			BCardText,
			BInputGroup,
			BInputGroupAppend,
			BSpinner,
			BRow,
			BCol,
			"icm-link": ICMLink,
		},
		mixins: [togglePasswordVisibility],
		data() {
			return {
				loading: false,
				loginData: {
					email: "",
					password: "",
					shouldPersistSession: false,
					suaEscolaIdealToken: null,
				},
			};
		},
		computed: {
			passwordToggleIcon() {
				return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
			},
		},
		methods: {
			async signIn() {
				this.loading = true;
				try {
					await this.$store.dispatch("auth/login", this.loginData);
					if (this.$route.query?.cursos) {
						this.$router.push("/cursos");
					} else {
						this.$router.push("/");
					}
				} catch (error) {
					this.loginData.suaEscolaIdealToken = null;
					this.$toast({
						component: ToastificationContent,
						props: {
							title: "Erro",
							text: error.message,
							variant: "danger",
							icon: "AlertCircleIcon",
						},
					});
				}
				this.loading = false;
			},
		},
		async mounted() {
			if (this.$route.query?.seiToken) {
				this.$store.dispatch("app/showLoading");
				this.loginData.suaEscolaIdealToken = this.$route.query.seiToken;
				await this.signIn();
				this.$store.dispatch("app/hideLoading");
			}
		},
	};
</script>

<style lang="scss">
	@import "@core/scss/vue/pages/page-auth.scss";

	.login-background {
		object-fit: cover;
		height: 100vh;
		width: 100%;
		border-radius: 0 20px 20px 0;
	}

	.wrapper {
		max-width: 343px;
		width: 100%;

		& > img {
			margin-bottom: 30px;
			width: 179px;
		}
	}
</style>
